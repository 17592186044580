import anime from "animejs"

export default class anchorLinkAction {
  constructor() {
  }

  setEvent(selectorAll) {
    selectorAll.forEach((elem)=>{
      const targetElement = document.getElementById(elem.dataset.anchorid)
      elem.addEventListener('click', ()=>{
        const clientRect = targetElement.getBoundingClientRect();
        const px = window.pageYOffset + clientRect.top;
        console.log(px)
        anime({
          targets: ['body','html'],
          scrollTop: px,
          duration: 1000,
          easing: 'easeOutCubic'
        })
      });
    })
  }


}
