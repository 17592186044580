/* ********** CSS ********** */
import scss from '../scss/main.scss'

import HandleMediaQuery from "./handleMediaQuery"
import Header from "./header"
import ScrollAnime from "./scrollAnime"
import AnchorLinkAction from "./anchorLinkAction"

let handleMediaQuery, header ,anchorLinkAction;

const setStyleVisid = (elem, display) => {
  for(let i = 0; i < elem.length; i++) {
    elem[i].style.display = display;
  }
}

/**
 * それぞれの処理
 * **/
const load = () => {
  anchorLinkAction = new AnchorLinkAction()
  anchorLinkAction.setEvent(document.querySelectorAll('.js-anchorLink'))
  const pcElem = document.querySelectorAll('.isPc')
  const spElem = document.querySelectorAll('.isSp')
  header = new Header();
  header.init()
  handleMediaQuery = new HandleMediaQuery();
  handleMediaQuery.on('mediaQueryChange', (isMedia) => {
    switch(isMedia) {
      case 'PC':
        setStyleVisid(pcElem, "")
        setStyleVisid(spElem, "none")
        break
      case 'SP':
        setStyleVisid(spElem, "")
        setStyleVisid(pcElem, "none")
        break
      default:
        break
    }
  });
  handleMediaQuery.change()

  const scrollAnime = new ScrollAnime();
  scrollAnime.setScrollmagicFadeIn(document.querySelectorAll('.js-scroll_fadein'))
  scrollAnime.setScrollmagicSideIn(document.querySelectorAll('.js-scroll_sidein'))


  /**
   * business の子カテゴリ のみ header の色を変えるため .c-header--businessLower 追加
   */
  const pathArr = window.location.pathname.split('/').filter(Boolean)
  if(pathArr[0] === 'business' && pathArr.length > 1){
    document.querySelector('header').classList.add('c-header--businessLower')
  }
};

window.addEventListener('load', () => {
  load();
})

