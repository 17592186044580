import anime from 'animejs'

export default class Header {
  constructor() {
    this.timeline = null;
    this.lock = false;
    this.openBtnElm = document.querySelector('.openNavi')
    this.navElm = document.querySelector('nav')

    this.logo = document.querySelector('.c-header .logo');
  }

  init() {

    const isScrollClass = () =>{
      if(window.pageYOffset > 0) {
        header.classList.add('scroll')
      } else {
        header.classList.remove('scroll')
      }
    }

    const header = document.querySelector('.c-header')
    window.addEventListener('scroll', (e) => {
      isScrollClass()
    });

    anime.set(this.navElm, {translateY: '-100%'})
    this.openBtnElm.addEventListener('click', this.isOpen.bind(this))

    this.timeline = anime.timeline({
      loopBegin: () => {
        if(this.checkOpen()) {
          anime.set(this.navElm, {translateY: 0})
          header.classList.remove('scroll')
        } else {
          anime.set(this.navElm, {translateY: '-100%'})
          isScrollClass()
        }
      },
      loopComplete: () => {
        if(this.checkOpen()) {
          anime.set(this.navElm, {translateY: 0})
          header.classList.remove('scroll')
        } else {
          anime.set(this.navElm, {translateY: '-100%'})
          isScrollClass()
        }
      }
    })
    .add({
      targets: 'nav .block',
      opacity: [0, 1],
      translateX: [-60, 0],
      scaleX: [0.5, 1],
      duration: 500,
      easing: 'easeOutCubic',
      delay: function(el, i, l) {
        return (i + 1) * 200;
      },
    })
    .add({
      targets: 'nav .block a',
      opacity: [0, 1],
      scale: [1.1, 1],
      duration: 400,
      easing: 'easeOutBack',
      delay: function(el, i, l) {
        return (i + 1) * 50;
      },
    }, '-=700')
    .add({
      targets: 'nav .company',
      opacity: [0, 1],
      translateY: [60, 0],
      duration: 500,
      easing: 'easeOutCubic',
      delay: function(el, i, l) {
        return (i + 1) * 100;
      },
    }, '-=500')
    .add({
      targets: 'nav ',
      backgroundColor: ['rgba(200,0,0,0)', 'rgba(200,0,0,1)'],
      duration: 300,
      easing: 'easeInOutSine',
    }, '-=500')

    this.timeline.reverse();
  }

  checkOpen() {
    return !!(this.openBtnElm.className.match(/isOpen/g));
  }

  isOpen(evn) {
    if(this.lock) {
      return false;
    }
    this.lock = true;
    if(!this.checkOpen()) {
      this.openBtnElm.classList.add('isOpen');
      anime.set(this.navElm, {translateY: 0});
      this.timeline.reverse();
      this.timeline.play();

      setTimeout(() => {
        this.logo.classList.add('isOpen');
      }, 1500);

    } else {
      this.openBtnElm.classList.remove('isOpen');
      this.logo.classList.remove('isOpen');
      this.timeline.reverse();
      this.timeline.play();
    }

    setTimeout(() => {
      this.lock = false;
    }, this.timeline.duration)
  }
}
