import anime from 'animejs'
import ScrollMagic from 'scrollmagic'
import 'debug.addIndicators'

export default class ScrollAnime{
  constructor() {
    // ScrollMagic Controller
    this.controller = new ScrollMagic.Controller()
    this.scenes = {
      header: null,
      basicOption: {
        triggerHook: 0.8,
        reverse: false
      }
    }
  }

  /**
   * setScrollmagicFadeIn フェードインアニメーション
   * @param elements {object}
   */
  setScrollmagicFadeIn(elements) {

    elements.forEach(elem => {
      let animation = anime.timeline({
        autoplay: false
      })
      .add({
        targets: elem,
        opacity: [0, 1],
        easing: 'easeInOutCubic',
        duration: 800
      })
      this.setScrollmagicBasicScene(elem, function() {
        animation.play()
      })
    });

  }

  /**
   * setScrollmagicSideIn 事業内容下層の画面端から出現するアニメーション
   * @param elements {object}
   */
  setScrollmagicSideIn(elements) {
    elements.forEach(elem => {
      this.setScrollmagicBasicScene(elem, function() {
        elem.classList.add('isSideIn');
      })
    })
  }

  /**
   * 一度実行したら消えるシーンの追加
   * @param _elem {object}
   * @param _action {function}
   */
  setScrollmagicBasicScene(_elem, _action) {
    let scene = new ScrollMagic.Scene({triggerElement: _elem, ...this.scenes.basicOption})
    .on("enter", (event) => {
      _action()
      this.controller.removeScene(scene)
    })
    // .addIndicators() // デバッグ用インジケーターの表示

    this.controller.addScene(scene)
  }
}
